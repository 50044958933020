import tokens from './tokens'
import { FarmConfig } from './types'

const midasFarms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'MIDAS-FTM',
    lpAddresses: {
      250: '0x02ba4291C54D179CAf7626A562E06328742ec40d',
      4002: '0xf1A842969d1EDCCD7D5DC2f7AD077D077E543776',
    },
    token: tokens.midas,
    quoteToken: tokens.wftm,
  },
]

export default midasFarms
