import { HelpIcon, TooltipOptions, useTooltip } from '@fbomb-finance/uikit-v2'
import React from 'react'


export default function QuestionHelper({ text, options }: { text: string, options?: TooltipOptions }) {
  const defaultOptions: TooltipOptions = {
    placement: "right"
  }

  const { tooltip, tooltipVisible, targetRef } = useTooltip(text, { ...defaultOptions, ...options })

  return (
    <span style={{ marginLeft: 4 }} ref={targetRef}>
      <HelpIcon />
      {tooltipVisible && tooltip}
    </span>
  )
}
