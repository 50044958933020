import tokens from "config/constants/tokens"

const pairs = [
  [
    tokens.bnb,
    tokens.wftm
  ],
  [
    tokens.bomb,
    tokens.wbomb
  ]
]

const wrapPairs = () => {
  let map: {
    [key: string]: {
      symbol: string;
      projectLink?: string;
      decimals?: number;
      address?: {
        [key: number]: string
      }
    }
  } = {}
  pairs.forEach(pair => {
    map = Object.assign(
      map, {
      [pair[0].symbol]: pair[1],
      [pair[1].symbol]: pair[0]
    }
    )
  })
  return map
}

export default wrapPairs