import React from 'react'
import { Menu as UikitMenu } from '@fbomb-finance/uikit-v2'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import { useBombUsdcPrice } from 'state/hooks'
import config, { mobileLinks } from './config'

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()
  const bombPriceUsd = useBombUsdcPrice()
  // const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      bombPriceUsd={bombPriceUsd}
      links={config(t)}
      mobileLinks={mobileLinks}
      {...props}
    />
  )
}

export default Menu
