import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getShrapnelAddress,
  getLotteryAddress,
  getLotteryTicketAddress,
  getLotteryV2Address,
  getMasterChefAddress,
  getMidasEmissionsAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getCakeVaultAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getBunnySpecialCakeVaultAddress,
  getBunnySpecialPredictionAddress,
  getWorkshopAddress,
  getWorkshopV1Address,
  getRoundFactoryAddress,
  getBombAddress,
  getLegacyRoundFactoryAddress,
  getLegacyRoundAddress,
  getRoundAddress,
  getWrappedBombAddress,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import shrapnelAbi from 'config/abi/shrap.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryAbi from 'config/abi/lottery.json'
import lotteryTicketAbi from 'config/abi/lotteryNft.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import midasEmissions from 'config/abi/midasEmissions.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import easterNftAbi from 'config/abi/easterNft.json'
import cakeVaultAbi from 'config/abi/cakeVault.json'
import predictionsAbi from 'config/abi/predictions.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialCakeVaultAbi from 'config/abi/bunnySpecialCakeVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import workshopAbi from 'config/abi/workshop.json'
import legacyRoundFactoryABI from 'config/abi/legacyRoundFactory.json'
import legacyRoundABI from 'config/abi/legacyRound.json'
import roundFactoryABI from 'config/abi/roundFactory.json'
import roundABI from 'config/abi/round.json'
import fantomRoundABI from 'config/abi/fantomRound.json'
import uniPairABI from 'config/abi/IUniswapV2Pair.json'
import wrappedBombAbi from 'config/abi/wrappedBomb.json'
import { RoundTypes } from 'state/types'

export const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getWorkshopContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(workshopAbi, getWorkshopAddress(), signer)
}
export const getWorkshopV1Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(workshopAbi, getWorkshopV1Address(), signer)
}
export const getPairContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(uniPairABI.abi, address, signer)
}
export const getRoundContract = (
  round: number | string,
  type?: RoundTypes,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const address = typeof round === 'number' ? getRoundAddress(round, type) : round
  return getContract(type === RoundTypes.Fantom ? fantomRoundABI : roundABI, address, signer)
}
export const getLegacyRoundContract = (round: number | string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = typeof round === 'number' ? getLegacyRoundAddress(round) : round
  return getContract(legacyRoundABI, address, signer)
}
export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer)
}
export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer)
}
export const getPointCenterIfoContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer)
}
export const getShrapnelContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(shrapnelAbi, getShrapnelAddress(), signer)
}
export const getProfileContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer)
}
export const getPancakeRabbitContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer)
}
export const getBunnyFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer)
}
export const getBunnySpecialContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer)
}
export const getLotteryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryAbi, getLotteryAddress(), signer)
}
export const getLotteryTicketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryTicketAbi, getLotteryTicketAddress(), signer)
}
export const getLotteryV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryV2Abi, getLotteryV2Address(), signer)
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getMidasEmissionsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(midasEmissions, getMidasEmissionsAddress(), signer)
}
export const getClaimRefundContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer)
}
export const getTradingCompetitionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer)
}
export const getEasterNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer)
}
export const getCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer)
}
export const getPredictionsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer)
}
export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer)
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getBunnySpecialCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialCakeVaultAbi, getBunnySpecialCakeVaultAddress(), signer)
}
export const getBunnySpecialPredictionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialPredictionAbi, getBunnySpecialPredictionAddress(), signer)
}
export const getLegacyRoundFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(legacyRoundFactoryABI, getLegacyRoundFactoryAddress(), signer)
}
export const getRoundFactoryContract = (type: RoundTypes, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(roundFactoryABI, getRoundFactoryAddress(type), signer)
}
export const getBombContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, getBombAddress(), signer)
}
export const getWrappedBombContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(wrappedBombAbi, getWrappedBombAddress(), signer)
}