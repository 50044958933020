import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@fbomb-finance/uikit-v2'

const StyledNav = styled.div`
  margin-bottom: 10px;
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  return (
    <StyledNav>
      <ButtonMenu activeIndex={activeIndex} scale="sm">
        <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
          Swap
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
          Liquidity
        </ButtonMenuItem>
        <ButtonMenuItem id="wrap-nav-link" to="/wrap" as={Link}>
          Wrap
        </ButtonMenuItem>
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
