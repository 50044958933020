/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall'
import roundABI from 'config/abi/round.json'
import legacyRoundABI from 'config/abi/legacyRound.json'
import fantomRoundABI from 'config/abi/fantomRound.json'
import { getRoundContract, getRoundFactoryContract } from 'utils/contractHelpers'
import { getRoundAddress } from 'utils/addressHelpers'
import { ITeam, IManhattanState, RoundTypes, Round } from '../types'

enum Team {
  TeamA = '1',
  TeamB = '2',
  TeamC = '3',
  TeamD = '4',
}

export const teams: { [key in Team]: ITeam } = {
  [Team.TeamA]: {
    name: 'Fredonia',
    philosophy: 'Benefit for the masses.',
  },
  [Team.TeamB]: {
    name: '81',
    philosophy: 'Fine... the masses will get some.',
  },
  [Team.TeamC]: {
    name: 'Third Regiment',
    philosophy: 'Winner takes all.',
  },
  [Team.TeamD]: {
    name: 'Balitan',
    philosophy: 'Outsiders are not welcomed.',
  },
}

const initialState: IManhattanState = {
  currentRound: 0,
  currentRoundLoaded: false,
  rounds: {
    [RoundTypes.Fantom]: {},
    [RoundTypes.Standard]: {},
    [RoundTypes.Legacy]: {},
  },
}

export const fetchCurrentRoundAsync = createAsyncThunk('mtm/fetchCurrentRoundAsync', async (type: RoundTypes) => {
  const contract = getRoundFactoryContract(type)
  const round = (await contract.currentRound()).toNumber()
  const roundContract = getRoundContract(round, type)
  const pot: string = (await roundContract.pot()).toJSON()
  return { roundNumber: round, pot, type }
})

export const fetchLastBuyerTeamAsync = createAsyncThunk<any, { round: number; lastBuyer: string; type: RoundTypes }>(
  'mtm/fetchLastBuyerTeamAsync',
  async ({ round, lastBuyer, type }) => {
    const contract = getRoundContract(round, type)
    const result = await contract.userTeamIds(lastBuyer)
    return { teamId: result.toNumber(), round, type }
  },
)

export const fetchRoundDataAsync = createAsyncThunk<
  Round & { round: number; type: RoundTypes },
  { round: number; type: RoundTypes }
>('mtm/fetchRoundDataAsync', async ({ round, type }) => {
  let address: string
  if (typeof round === 'number') {
    address = getRoundAddress(round, type)
  } else {
    address = round
  }
  const calls = [
    {
      address,
      name: 'pot',
    },
    {
      address,
      name: 'getTimeLeft',
    },
    {
      address,
      name: 'totalSupply',
    },
    {
      address,
      name: 'hasEnded',
    },
    {
      address,
      name: 'roundEndTimestamp',
    },
    {
      address,
      name: type === RoundTypes.Legacy ? 'lastKeyBuyer' : 'lastCodeBuyer',
    },
    {
      address,
      name: 'maxAccumulatedTime',
    },
  ]

  let abi = type === RoundTypes.Legacy ? legacyRoundABI : roundABI
  let result = await multicall(abi, calls)

  // serialize BigNumbers
  result = result.map((res) => {
    return res[0]._isBigNumber ? new BigNumber(res).toJSON() : res[0]
  })

  // transform result into object with variable names
  let keys = ['potSize', 'timeLeft', 'keysSupply', 'hasEnded', 'roundEndTimestamp', 'lastKeyBuyer', 'maxTime']

  result = Object.fromEntries(
    keys.map((key, index) => {
      return [keys[index], result[index]]
    }),
  )

  // append fetch data
  result.round = round
  result.type = type

  return result
})

export const fetchRoundUserDataAsync = createAsyncThunk<any, { account: string; round: number; type: RoundTypes }>(
  'mtm/fetchRoundUserDataAsync',
  async ({ account, round, type }) => {
    let contract = getRoundAddress(round, type)
    if (type === RoundTypes.Standard && round === 1) {
      contract = '0xB3818cAE126DD9e20c0c6Db8598b01d5793EDeC6'
    }
    const calls = [
      {
        address: contract,
        name: 'userTeamIds',
        params: [account],
      },
      {
        address: contract,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: contract,
        name: type === RoundTypes.Fantom ? 'pendingRewards' : 'pendingBombRewards',
        params: [account],
      },
    ]

    let result = (await multicall(type === RoundTypes.Fantom ? fantomRoundABI : roundABI, calls)).map((res) =>
      new BigNumber(res).toJSON(),
    )

    return {
      userTeam: result[0],
      keysBought: result[1],
      pendingRewards: result[2],
      round,
      type,
    }
  },
)

export const mtmSlice = createSlice({
  name: 'MTM',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLastBuyerTeamAsync.fulfilled, (state, action) => {
      const { teamId, round, type } = action.payload
      state.rounds[type][round] = {
        ...state.rounds[type][round],
        lastBuyerTeam: teamId,
      }
    })

    builder.addCase(fetchCurrentRoundAsync.fulfilled, (state, action) => {
      const { roundNumber, pot } = action.payload
      state.currentRound = roundNumber
      state.pot = pot
      state.currentRoundLoaded = true
    })

    builder.addCase(fetchRoundDataAsync.fulfilled, (state, action) => {
      const { potSize, timeLeft, keysSupply, hasEnded, roundEndTimestamp, lastKeyBuyer, maxTime, round, type } =
        action.payload

      // check if there's enough difference (12 seconds) in the time left before updating state
      // this is to avoid glitches triggered by delays in fetching data
      const lastRoundState = state.rounds[type][round]
      const isTimeDiscrepant =
        !lastRoundState ||
        new BigNumber(lastRoundState.timeLeft)
          .minus(Date.now() / 1000 - lastRoundState.lastTimeLeftTimestamp)
          .minus(timeLeft)
          .abs()
          .gt(12) ||
        new BigNumber(timeLeft).isZero()

      const lastTimeLeftTimestamp =
        isTimeDiscrepant || !lastRoundState.lastTimeLeftTimestamp
          ? Date.now() / 1000
          : lastRoundState.lastTimeLeftTimestamp

      state.rounds[type][round] = {
        ...state.rounds[type][round],
        lastTimeLeftTimestamp,
        potSize,
        timeLeft: isTimeDiscrepant || !lastRoundState.timeLeft ? timeLeft : lastRoundState.timeLeft,
        keysSupply,
        hasEnded,
        roundEndTimestamp,
        lastKeyBuyer,
        maxTime,
        loaded: true,
      }
    })

    builder.addCase(fetchRoundUserDataAsync.fulfilled, (state, action: PayloadAction<any>) => {
      const { userTeam, keysBought, pendingRewards, round, type } = action.payload
      state.rounds[type] = {
        ...state.rounds[type],
        [round]: {
          ...state.rounds[type][round],
          userData: {
            loaded: true,
            teamID: new BigNumber(userTeam).toNumber(),
            keysBought,
            pendingRewards,
          },
        },
      }
    })
  },
})

export default mtmSlice.reducer
