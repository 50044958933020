import { useCallback, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { farmsConfig, midasFarmsConfig } from 'config/constants'
import { simpleRpcProvider } from 'utils/providers'
import { getBalanceAmount } from 'utils/formatBalance'
import { BIG_ZERO } from 'utils/bigNumber'
import useRefresh from 'hooks/useRefresh'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import {
  fetchFarmsPublicDataAsync,
  fetchMidasFarmsPublicDataAsync,
  setBlock,
  fetchTokenData,
  fetchRoundDataAsync,
  fetchRoundUserDataAsync,
  fetchCurrentRoundAsync,
} from './actions'
import { State, Farm, FarmsState, RoundTypes, MidasFarmState, MidasFarm } from './types'
import { fetchFarmUserDataAsync, nonArchivedFarms } from './farms'
import { fetchMidasFarmUserDataAsync, nonArchivedMidasFarms } from './midasFarms'
import { fetchLastBuyerTeamAsync } from './mtm'

export const usePollFarmsData = (includeArchive = true) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

export const usePollMidasFarmsData = (includeArchive = true) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? midasFarmsConfig : nonArchivedMidasFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchMidasFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchMidasFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

/**
 * Fetches the "core" farm data used globally
 * 251 = CAKE-BNB LP
 * 252 = BUSD-BNB LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync([251, 252]))
  }, [dispatch, fastRefresh])
}

export const usePollBlockNumber = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const interval = setInterval(async () => {
      const blockNumber = await simpleRpcProvider.getBlockNumber()
      dispatch(setBlock(blockNumber))
    }, 6000)

    return () => clearInterval(interval)
  }, [dispatch])
}

// Farms

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromLpSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  }
}

// Midas Farms

export const useMidasFarms = (): MidasFarmState => {
  const midasFarms = useSelector((state: State) => state.midasFarms)
  return midasFarms
}

export const useMidasFarmFromPid = (pid): MidasFarm => {
  const midasFarm = useSelector((state: State) => state.midasFarms.data.find((f) => f.pid === pid))
  return midasFarm
}

export const useMidasFarmFromLpSymbol = (lpSymbol: string): MidasFarm => {
  const midasFarm = useSelector((state: State) => state.midasFarms.data.find((f) => f.lpSymbol === lpSymbol))
  return midasFarm
}

export const useMidasFarmUser = (pid) => {
  const farm = useMidasFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? farm.userData.earnings.map((rewards) => new BigNumber(rewards)) : [BIG_ZERO, BIG_ZERO],
  }
}

// Return a farm for a given token symbol. The farm is filtered based on attempting to return a farm with a quote token from an array of preferred quote tokens
export const useFarmFromTokenSymbol = (tokenSymbol: string, preferredQuoteTokens?: string[]): Farm => {
  const farms = useSelector((state: State) => state.farms.data.filter((farm) => farm.token.symbol === tokenSymbol))
  const filteredFarm = filterFarmsByQuoteToken(farms, preferredQuoteTokens)
  return filteredFarm
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid)
  return farm && new BigNumber(farm.token.busdPrice)
}

// Return the base token price for a farm, from a given midas pid
export const useBusdPriceFromMidasPid = (pid: number): BigNumber => {
  const farm = useMidasFarmFromPid(pid)
  return farm && new BigNumber(farm.token.busdPrice)
}

export const useBusdPriceFromToken = (tokenSymbol: string): BigNumber => {
  const tokenFarm = useFarmFromTokenSymbol(tokenSymbol)
  const tokenPrice = useBusdPriceFromPid(tokenFarm?.pid)
  return tokenPrice
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid)
  let lpTokenPrice = BIG_ZERO

  if (farm.lpTotalSupply && farm.lpTotalInQuoteToken) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(new BigNumber(farm.lpTotalSupply))
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

export const useMidasLpTokenPrice = (symbol: string) => {
  const farm = useMidasFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = useBusdPriceFromMidasPid(farm.pid)
  let lpTokenPrice = BIG_ZERO

  if (farm.lpTotalSupply && farm.lpTotalInQuoteToken) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(new BigNumber(farm.lpTotalSupply))
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

export const usePriceBnbBusd = (): BigNumber => {
  const bnbBusdFarm = useFarmFromPid(1)
  return new BigNumber(bnbBusdFarm.quoteToken.busdPrice)
}

export const usePriceCakeBusd = (): BigNumber => {
  const price = new BigNumber(useSelector((state: State) => state.tokens.prices.shrap))
  return price
}

export const usePriceMidasBusd = (): BigNumber => {
  const price = new BigNumber(useSelector((state: State) => state.tokens.prices.midas))
  return price
}

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}

// Voting
export const useGetProposals = () => {
  const proposals = useSelector((state: State) => state.voting.proposals)
  return Object.values(proposals)
}

export const useGetProposal = (proposalId: string) => {
  const proposal = useSelector((state: State) => state.voting.proposals[proposalId])
  return proposal
}

export const useGetVotes = (proposalId: string) => {
  const votes = useSelector((state: State) => state.voting.votes[proposalId])
  return votes || []
}

export const useGetVotingStateLoadingStatus = () => {
  const votingStatus = useSelector((state: State) => state.voting.voteLoadingStatus)
  return votingStatus
}

export const useGetProposalLoadingStatus = () => {
  const votingStatus = useSelector((state: State) => state.voting.proposalLoadingStatus)
  return votingStatus
}

// Tokens

export const useTokensData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchTokenData())
  }, [dispatch, slowRefresh])
}

export const useTokensDataLoading = () => {
  return useSelector((state: State) => state.tokens.isLoading)
}

export const useBombUsdcPrice = () => {
  const price = new BigNumber(useSelector((state: State) => state.tokens.prices.bomb)).toNumber()
  return price
}

export const useBombSupply = () => {
  const supply = new BigNumber(useSelector((state: State) => state.tokens.supply.bomb)).toNumber()
  return supply
}

export const useShrapUsdcPrice = () => {
  const price = new BigNumber(useSelector((state: State) => state.tokens.prices.shrap))
  return price
}

export const useShrapSupply = () => {
  const supply = new BigNumber(useSelector((state: State) => state.tokens.supply.shrap)).toNumber()
  return supply
}

export const useFtmUsdcPrice = () => {
  const price = new BigNumber(useSelector((state: State) => state.tokens.prices.ftm))
  return price
}

export const useMidasUsdcPrice = () => {
  const price = new BigNumber(useSelector((state: State) => state.tokens.prices.midas))
  return price
}

// MtM

export const usePollMtmData = (type: RoundTypes) => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchCurrentRoundAsync(type))
  }, [dispatch, fastRefresh, type])
}

export const usePollLastBuyerTeam = (round: number, lastBuyer: string, type: RoundTypes) => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (lastBuyer) {
      dispatch(fetchLastBuyerTeamAsync({ round, lastBuyer, type }))
    }
  }, [dispatch, fastRefresh, lastBuyer, type, round])
}

export const usePollMtmRound = (round: number, type: RoundTypes) => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    if (round !== 0) {
      dispatch(fetchRoundDataAsync({ round, type }))
      if (account) {
        dispatch(fetchRoundUserDataAsync({ account, round, type }))
      }
    }
  }, [account, dispatch, round, fastRefresh, type])
}

export const usePollRoundHistory = (currentRound: number, type: RoundTypes) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const doPoll = useCallback(() => {
    if (currentRound <= 0) return

    for (let i = currentRound; i > 0; i--) {
      dispatch(fetchRoundDataAsync({ round: i, type }))
      if (account) {
        dispatch(fetchRoundUserDataAsync({ account, round: i, type }))
      }
    }

    if (type === RoundTypes.Standard) {
      for (let id = 1; id <= 2; id++) {
        dispatch(fetchRoundDataAsync({ round: id, type: RoundTypes.Legacy }))
      }
    }
  }, [account, dispatch, currentRound, type])

  useEffect(() => {
    doPoll()
  }, [doPoll])

  return { doPoll }
}

export const useMtmData = () => {
  return useSelector((state: State) => state.mtm)
}

export const useMtmRoundData = (round: number, type: RoundTypes) => {
  return useSelector((state: State) => state.mtm.rounds[type][round])
}
