import { ChainId } from '@fbomb-finance/exchange-sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'
import codehashes from 'config/constants/codehashes'
import getCreate2 from 'utils/getCreate2'
import { RoundTypes } from 'state/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getBombAddress = () => {
  return getAddress(tokens.bomb.address)
}
export const getWrappedBombAddress = () => {
  return getAddress(tokens.wbomb.address)
}
export const getUsdcAddress = () => {
  return getAddress(tokens.usdc.address)
}
export const getWorkshopAddress = () => {
  return getAddress(addresses.workshop)
}
export const getWorkshopV1Address = () => {
  return getAddress(addresses.workshopV1)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMidasEmissionsAddress = () => {
  return getAddress(addresses.midasEmissions)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWftmAddress = () => {
  return getAddress(tokens.wftm.address)
}
export const getShrapnelAddress = () => {
  return getAddress(tokens.shrap.address)
}
export const getMidasAddress = () => {
  return getAddress(tokens.midas.address)
}
export const getLotteryAddress = () => {
  return getAddress(addresses.lottery)
}
export const getLotteryTicketAddress = () => {
  return getAddress(addresses.lotteryNFT)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getLegacyRoundFactoryAddress = () => {
  return getAddress(addresses.legacyRoundFactory)
}
export const getRoundFactoryAddress = (type: RoundTypes) => {
  switch (type) {
    case RoundTypes.Standard:
      return getAddress(addresses.roundFactory)
    case RoundTypes.Fantom:
      return getAddress(addresses.fantomRoundFactory)
    case RoundTypes.Legacy:
      return getAddress(addresses.legacyRoundFactory)
    default:
      throw new Error(`Unknown round type: ${type}`)
  }
}
export const getRoundAddress = (round: number, type: RoundTypes) => {
  let codehash

  if (type === RoundTypes.Standard) {
    codehash = codehashes.mtm
  } else if (type === RoundTypes.Fantom) {
    codehash = codehashes.mtmFantom
  } else if (type === RoundTypes.Legacy) {
    codehash = codehashes.mtmLegacy
  }
  return getCreate2(getRoundFactoryAddress(type), round, codehash)
}

export const getLegacyRoundAddress = (round: number) => {
  return getCreate2(getLegacyRoundFactoryAddress(), round, codehashes.mtmLegacy)
}
