import multicall from 'utils/multicall'
import erc20 from 'config/abi/erc20.json'
import BigNumber from 'bignumber.js'
import { getWftmAddress, getUsdcAddress } from 'utils/addressHelpers'

export const getFtmPrice = async () => {
  const usdcAddress = getUsdcAddress()
  const ftmAddress = getWftmAddress()
  const spookyLpAddress = '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c'

  const calls = [
    {
      address: usdcAddress,
      name: 'balanceOf',
      params: [spookyLpAddress],
    },
    {
      address: ftmAddress,
      name: 'balanceOf',
      params: [spookyLpAddress],
    },
  ]
  const [usdcBalance, ftmBalance]: BigNumber[] = await multicall(erc20, calls)

  return new BigNumber(usdcBalance).times(10 ** 12).div(ftmBalance)
}

export const getTokenPrice = async (tokenA, tokenB, lpAddress) => {
  const calls = [
    {
      address: tokenA,
      name: 'balanceOf',
      params: [lpAddress],
    },
    {
      address: tokenB,
      name: 'balanceOf',
      params: [lpAddress],
    },
  ]
  const [balanceA, balanceB]: BigNumber[] = await multicall(erc20, calls)

  return new BigNumber(balanceB).div(balanceA)
}
