import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  width: 100%;
  padding: 32px 16px;

  background-image: url('/images/bomb-chan.png'); //, url('/images/bomb_explosion_corner.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
  background-size: 40%;
  min-height: calc(100vh - 65px);

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: 50%;
  }

  /* ${({ theme }) => theme.mediaQueries.lg} {
    background-image: url('/images/arch-${({ theme }) => (theme.isDark ? 'dark' : 'light')}.svg'),
      url('/images/left-pancake.svg'), url('/images/right-pancake.svg');
    background-repeat: no-repeat;
    background-position: center 420px, 10% 230px, 90% 230px;
    background-size: contain, 266px, 266px;
    min-height: 90vh;
  } */
`

export default Container
