import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'SHRAP-FTM',
    lpAddresses: {
      4002: '0x4b32aF2B6D6A823Ee8022F82e2d0558e39D2AFAb',
      250: '0xE69b45BE6260634de4e432F66179ce47EE846800',
    },
    token: tokens.shrap,
    quoteToken: tokens.wftm,
  },
  {
    pid: 1,
    lpSymbol: 'BOMB-FTM',
    lpAddresses: {
      250: '0x8264f247E4571e2879A05D5d2115C26406c0bC2b',
    },
    token: tokens.bomb,
    quoteToken: tokens.wftm,
  },
  {
    pid: 2,
    lpSymbol: 'BOMB-SHRAP',
    lpAddresses: {
      250: '0x4710D96DCAeD0dBb1f82E77F620C03C1D9f4A5e1',
    },
    token: tokens.bomb,
    quoteToken: tokens.shrap,
  },
  {
    pid: 3,
    lpSymbol: 'BOMB-USDC',
    lpAddresses: {
      250: '0xFD98aa53999613CfFB57a784553e7B3F6D4e25B8',
    },
    token: tokens.bomb,
    quoteToken: tokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'SHRAP-USDC',
    lpAddresses: {
      250: '0x873fa944414F569B23649EB92DBB254340B6b25D',
    },
    token: tokens.shrap,
    quoteToken: tokens.usdc,
  },
  {
    pid: 5,
    lpSymbol: 'USDC-FTM',
    lpAddresses: {
      250: '0x82c40B555f2dbb6704f214d984da3efdDE64711C',
    },
    token: tokens.wftm,
    quoteToken: tokens.usdc,
  },
  {
    pid: 6,
    lpSymbol: 'WBTC-FTM',
    lpAddresses: {
      250: '0x874bB203aBb6AB8A791b56277B680c555f29aB1c',
    },
    token: tokens.wbtc,
    quoteToken: tokens.wftm,
  },
  {
    pid: 7,
    lpSymbol: 'WETH-FTM',
    lpAddresses: {
      250: '0xDe8908Bc8aA9Fe4515079E2Dbc9380f659dFAd62',
    },
    token: tokens.weth,
    quoteToken: tokens.wftm,
  },
  /* {
    pid: 8,
    ////
    ////
    ////     DUMMY
    ////
    ////
  }, */
  {
    pid: 9,
    lpSymbol: 'bBOMB-FTM',
    lpAddresses: {
      250: '0x5f6158c03E773cdAAa93359007760C5A3Fa64a83',
    },
    token: tokens.bbomb,
    quoteToken: tokens.wftm,
  },
  {
    pid: 10,
    lpSymbol: 'bBOMB-BOMB',
    lpAddresses: {
      250: '0x96C45D50C3C702E114cCC92c837aD2091B4b5C34',
    },
    token: tokens.bbomb,
    quoteToken: tokens.bomb,
  },
  {
    pid: 11,
    lpSymbol: 'MIM-FTM',
    lpAddresses: {
      250: '0x74928bFaE5D4dE45Df48d9652DE02ecE529E8729',
    },
    token: tokens.mim,
    quoteToken: tokens.wftm,
  },
  {
    pid: 12,
    lpSymbol: 'PGUNK-FTM',
    lpAddresses: {
      250: '0xFf8E84070D542e816797C1F0f2CAd97AFFF6B2de',
    },
    token: tokens.pgunk,
    quoteToken: tokens.wftm,
  },
  {
    pid: 13,
    lpSymbol: 'SHRAP-SPIRIT',
    lpAddresses: {
      250: '0x6bdD61a00075F2141B8B9C2cf4569AD6d6db0422',
    },
    token: tokens.spirit,
    quoteToken: tokens.shrap,
  },
  {
    pid: 14,
    lpSymbol: 'SHRAP-BOO',
    lpAddresses: {
      250: '0x0aDA4ffFE4c4b184Ceaec8Eac15A74Dc3a92Cd80',
    },
    token: tokens.boo,
    quoteToken: tokens.shrap,
  },
  {
    pid: 15,
    lpSymbol: 'SHRAP-GRIM',
    lpAddresses: {
      250: '0x9c5Abd8C656203B3A98840c048aAFd5062Cb5694',
    },
    token: tokens.grim,
    quoteToken: tokens.shrap,
  },
  {
    pid: 16,
    lpSymbol: 'SHRAP-REAPER',
    lpAddresses: {
      250: '0xDddbB150F6b5AE71975CF58c136e1d184cd1666F',
    },
    token: tokens.reaper,
    quoteToken: tokens.shrap,
  },
  {
    pid: 17,
    lpSymbol: 'SHRAP-SPELL',
    lpAddresses: {
      250: '0x35cc1D63e80f609fD8B48D8348Be3E43B499B69F',
    },
    token: tokens.spell,
    quoteToken: tokens.shrap,
  },
  {
    pid: 18,
    lpSymbol: 'SHRAP-ICE',
    lpAddresses: {
      250: '0x56927539094fd4005Ed394C95C26B8765E1285f1',
    },
    token: tokens.ice,
    quoteToken: tokens.shrap,
  },
  {
    pid: 19,
    lpSymbol: 'SHRAP-GEIST',
    lpAddresses: {
      250: '0x0FeE3b9986cfa7DE32C8DDE5e09c870a2cf42178',
    },
    token: tokens.geist,
    quoteToken: tokens.shrap,
  },
  {
    pid: 20,
    lpSymbol: 'SHRAP-SCREAM',
    lpAddresses: {
      250: '0xbf4C7f304e976659063C20A16BF261cd4DC5A803',
    },
    token: tokens.scream,
    quoteToken: tokens.shrap,
  },
  {
    pid: 21,
    lpSymbol: 'SHRAP-ZOO',
    lpAddresses: {
      250: '0x6C241F590f698DDe62C1f5D3AEb1e5Ba500AA22E',
    },
    token: tokens.zoo,
    quoteToken: tokens.shrap,
  },
  {
    pid: 22,
    lpSymbol: 'SHRAP-TOMB',
    lpAddresses: {
      250: '0x3358bbAc25Ebb3e52D7C34E4BFD072b8A070450f',
    },
    token: tokens.tomb,
    quoteToken: tokens.shrap,
  },
  {
    pid: 23,
    lpSymbol: 'SHRAP-BEETS',
    lpAddresses: {
      250: '0x25baFB57922A90a50A19931d782fCb8a2adC052E',
    },
    token: tokens.beets,
    quoteToken: tokens.shrap,
  },
  {
    pid: 24,
    lpSymbol: 'SHRAP-SHADE',
    lpAddresses: {
      250: '0x45069F86c8aBafaa12866F20c095c244dfF519E5',
    },
    token: tokens.shade,
    quoteToken: tokens.shrap,
  },
  {
    pid: 25,
    lpSymbol: 'SHRAP-TAROT',
    lpAddresses: {
      250: '0x18C385A842B1000670d3799840A17c2F7c18842c',
    },
    token: tokens.tarot,
    quoteToken: tokens.shrap,
  },
  {
    pid: 26,
    lpSymbol: 'SHRAP-KEK',
    lpAddresses: {
      250: '0x488C8e2E6B6Ce79e77D2C2E9f342C1ca67B0A76C',
    },
    token: tokens.kek,
    quoteToken: tokens.shrap,
  },
  {
    pid: 27,
    lpSymbol: 'SHRAP-COFFIN',
    lpAddresses: {
      250: '0x9aBE1B7eA0225E536CBf4fC5E916e96c2634c46E',
    },
    token: tokens.coffin,
    quoteToken: tokens.shrap,
  },
  {
    pid: 28,
    lpSymbol: 'SHRAP-sSPELL',
    lpAddresses: {
      250: '0x86238135BD5AAf66e88a14dE905C4CbC4A010749',
    },
    token: tokens.sspell,
    quoteToken: tokens.shrap,
  },
  {
    pid: 29,
    lpSymbol: 'SHRAP-nICE',
    lpAddresses: {
      250: '0xb687F9dcdFCAf2A2cFA2f75aDD955f2CAb6E6f02',
    },
    token: tokens.nice,
    quoteToken: tokens.shrap,
  },
  {
    pid: 30,
    lpSymbol: 'SHRAP-ECHO',
    lpAddresses: {
      250: '0xA8BC6f369b29468A6Ef6ac75CE58E2b564Ec49Eb',
    },
    token: tokens.echo,
    quoteToken: tokens.shrap,
  },
  {
    pid: 31,
    lpSymbol: 'SHRAP-Death',
    lpAddresses: {
      250: '0x7048881ce5A10Cca3A33Ccf923d480FE81EcF36e',
    },
    token: tokens.death,
    quoteToken: tokens.shrap,
  },
  {
    pid: 32,
    lpSymbol: 'SHRAP-wMEMO',
    lpAddresses: {
      250: '0x5A4F8f87226565943DD8bF8BBa3268BF2Dd3624E',
    },
    token: tokens.wmemeo,
    quoteToken: tokens.shrap,
  },
  {
    pid: 33,
    lpSymbol: 'CoUSD-MIM',
    lpAddresses: {
      250: '0x7D4B6528Bc5BCb0eCd8C853A0aBb7aFc4aec3C4d',
    },
    token: tokens.cousd,
    quoteToken: tokens.mim,
  },
  {
    pid: 36,
    lpSymbol: 'MIDAS-FTM',
    lpAddresses: {
      250: '0x02ba4291C54D179CAf7626A562E06328742ec40d',
    },
    token: tokens.midas,
    quoteToken: tokens.wftm,
  },
  {
    pid: 37,
    lpSymbol: 'SMART-SHRAP',
    lpAddresses: {
      250: '0x8dFfb91290E4fB8779beE5A919289f4df9aBBb9c',
    },
    token: tokens.smart,
    quoteToken: tokens.shrap,
  },
  {
    pid: 38,
    lpSymbol: 'SLURP-SHRAP',
    lpAddresses: {
      250: '0xD917fd048B8a462f7F3A13E9ffa1827BFEF968BF',
    },
    token: tokens.slurp,
    quoteToken: tokens.shrap,
  },
  {
    pid: 39,
    lpSymbol: 'PGUNK-SHRAP',
    lpAddresses: {
      250: '0xe7af67a03aE36cB1cEb5a845d22B4f11391aBcf7',
    },
    token: tokens.pgunk,
    quoteToken: tokens.shrap,
  },
  {
    pid: 40,
    lpSymbol: 'QI-SHRAP',
    lpAddresses: {
      250: '0x199AB6903670Ea77e0725Ee426de133cd7E39D95',
    },
    token: tokens.qi,
    quoteToken: tokens.shrap,
  },
  {
    pid: 41,
    lpSymbol: 'bDANI-BOMB',
    lpAddresses: {
      250: '0x767Fa7f3512459D493EabCBf0a4A61A55ddF4E82',
    },
    token: tokens.bdani,
    quoteToken: tokens.bomb,
  },
  {
    pid: 42,
    lpSymbol: 'anyFSN-FTM',
    lpAddresses: {
      250: '0x9a70e627BeF5c382EA4AB555F5D05092de4Eee23',
    },
    token: tokens.anyfsn,
    quoteToken: tokens.wftm,
  },
  /* {
    pid: 43,
    ////
    ////
    ////     MIDAS DUMMY
    ////
    ////
  }, */
  {
    pid: 44,
    lpSymbol: 'NOVA-FTM',
    lpAddresses: {
      250: '0xBeF5eA523Ca88ABb821D47a4bd872d66104FF3F5',
    },
    token: tokens.nova,
    quoteToken: tokens.wftm,
  },
  {
    pid: 45,
    lpSymbol: 'WBOMB-BOMB',
    lpAddresses: {
      250: '0xb102Df1724D20c3175AB1Cdf77C466D02BD521D2',
    },
    token: tokens.wbomb,
    quoteToken: tokens.bomb,
  },
  {
    pid: 46,
    lpSymbol: 'WBOMB-FTM',
    lpAddresses: {
      250: '0x7D26c7d8c9A370f9a49E0dFe99F7DDd0ef2d8a34',
    },
    token: tokens.wbomb,
    quoteToken: tokens.wftm,
  },
  {
    pid: 47,
    lpSymbol: 'WBOMB-SHRAP',
    lpAddresses: {
      250: '0x77A346D6B40ED13C7365a99d8F9C6FEe3f08EB5D',
    },
    token: tokens.wbomb,
    quoteToken: tokens.shrap,
  },
  {
    pid: 48,
    lpSymbol: 'WBOMB-bBOMB',
    lpAddresses: {
      250: '0x922955b15802Fc818135aB479Fc372017206293C',
    },
    token: tokens.bbomb,
    quoteToken: tokens.wbomb,
  },
  {
    pid: 49,
    lpSymbol: 'WBOMB-bDANI',
    lpAddresses: {
      250: '0x648c32d5E538A645a8051Ee2011FB7c52E1D2c82',
    },
    token: tokens.bdani,
    quoteToken: tokens.wbomb,
  },
  {
    pid: 50,
    lpSymbol: 'WBOMB-KEK',
    lpAddresses: {
      250: '0x6D157585431257C9540585C81a21A8227B1F2532',
    },
    token: tokens.kek,
    quoteToken: tokens.wbomb,
  },
  {
    pid: 51,
    lpSymbol: 'WBOMB-COFFIN',
    lpAddresses: {
      250: '0xEa5cF580924e5a52BCD9A385f4aa919668B007F1',
    },
    token: tokens.coffin,
    quoteToken: tokens.wbomb,
  },
  {
    pid: 52,
    lpSymbol: 'WBOMB-SMART',
    lpAddresses: {
      250: '0x34D33dc8Ac6f1650D94A7E9A972B47044217600b',
    },
    token: tokens.smart,
    quoteToken: tokens.wbomb,
  },
  {
    pid: 53,
    lpSymbol: 'WBOMB-SLURP',
    lpAddresses: {
      250: '0xA770760D7f17305F46Cd4749FBf5A6C951e3cB63',
    },
    token: tokens.slurp,
    quoteToken: tokens.wbomb,
  },
  {
    pid: 54,
    lpSymbol: 'WBOMB-MIDAS',
    lpAddresses: {
      250: '0xCBC061f0A8Fa10665BD6cBe71D7dAF6cDb2c6Fa0',
    },
    token: tokens.midas,
    quoteToken: tokens.wbomb,
  },
]

export default farms
