import { MenuEntry } from '@fbomb-finance/uikit-v2'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = () => [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    bombLabel: 'swap',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Bombing Range',
    shortLabel: 'Farm',
    bombLabel: 'swap',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'The Workshop',
    shortLabel: 'Stake',
    bombLabel: 'swap',
    icon: 'WorkshopIcon',
    href: '/staking',
  },
  {
    label: 'Minutes to Midnight',
    shortLabel: 'Play',
    bombLabel: 'play',
    icon: 'MtmIcon',
    href: '/mtm',
    isNew: true,
  },
  {
    label: 'Partners',
    icon: 'PartnershipIcon',
    href: '/partners',
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Wrap',
        href: '/wrap'
      },
      {
        label: 'Info',
        href: 'https://info.fbomb.finance'
      },
      {
        label: 'Voting',
        href: 'https://vote.fbomb.finance',
      },
      {
        label: 'Github',
        href: 'https://github.com/fbomb-finance',
      },
      {
        label: 'Docs',
        href: 'https://docs.fbomb.finance',
      },
    ],
  },
]

export const mobileLinks = [
  {
    icon: "HomeIcon",
    href: "/"
  },
  {
    icon: "TradeIcon",
    href: "/swap"
  },
  {
    icon: "FarmIcon",
    href: "/farms"
  },
  {
    icon: "MtmIcon",
    href: "/mtm"
  },
]

export default config
