import { useMemo } from 'react'
import {
  getBep20Contract,
  getShrapnelContract,
  getMasterchefContract,
  getMidasEmissionsContract,
  getWorkshopContract,
  getErc721Contract,
  getWorkshopV1Contract,
  getRoundContract,
  getRoundFactoryContract,
  getBombContract,
  getLegacyRoundContract,
  getPairContract,
  getWrappedBombContract,
} from 'utils/contractHelpers'
import { RoundTypes } from 'state/types'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@fbomb-finance/exchange-sdk'
// import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { getMulticallAddress } from 'utils/addressHelpers'
import ENS_PUBLIC_RESOLVER_ABI from '../config/swap/abis/ens-public-resolver.json'
import ENS_ABI from '../config/swap/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/swap/abis/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'
import { getContract } from '../utils'
import useWeb3Provider from './useWeb3Provider'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const { library } = useWeb3Provider()
  return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library])
}

export const usePairContract = (address: string) => {
  const { library } = useWeb3Provider()
  return useMemo(() => getPairContract(address, library.getSigner()), [address, library])
}

export const useRoundContract: ((address: string) => any) & ((address: number, type: RoundTypes) => any) = (
  address,
  type?,
) => {
  const { library } = useWeb3Provider()
  return useMemo(() => getRoundContract(address, type, library.getSigner()), [address, library, type])
}
export const useLegacyRoundContract = (address: number | string) => {
  const { library } = useWeb3Provider()
  return useMemo(() => getLegacyRoundContract(address, library.getSigner()), [address, library])
}
export const useFantomRoundContract = (address: number | string) => {
  const { library } = useWeb3Provider()
  return useMemo(() => getRoundContract(address, RoundTypes.Fantom, library.getSigner()), [address, library])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const { library } = useWeb3Provider()
  return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

export const useShrapnel = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getShrapnelContract(library.getSigner()), [library])
}

export const useBomb = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getBombContract(library.getSigner()), [library])
}

export const useWrappedBomb = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getWrappedBombContract(library.getSigner()), [library])
}

export const useShrapnelContract = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getShrapnelContract(library.getSigner()), [library])
}

export const useMasterchef = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getMasterchefContract(library.getSigner()), [library])
}

export const useMidasEmissions = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getMidasEmissionsContract(library.getSigner()), [library])
}

export const useWorkshop = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getWorkshopContract(library.getSigner()), [library])
}

export const useWorkshopV1 = () => {
  const { library } = useWeb3Provider()
  return useMemo(() => getWorkshopV1Contract(library.getSigner()), [library])
}

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useWeb3Provider()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3Provider()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3Provider()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  return useContract(getMulticallAddress(), multiCallAbi, false)
}

export const useRoundFactoryContract = (type: RoundTypes) => {
  const { library } = useWeb3Provider()
  return useMemo(() => getRoundFactoryContract(type, library.getSigner()), [library, type])
}
