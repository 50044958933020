/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
// import multicall from "utils/multicall"
import { getContract, getShrapnelContract } from 'utils/contractHelpers'
import erc20 from 'config/abi/erc20.json'
import { getBombAddress, getMidasAddress, getShrapnelAddress, getWftmAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getFtmPrice, getTokenPrice } from './helpers'

export const fetchTokenData = createAsyncThunk('prices/fetchTokenData', async () => {
  try {
    const bombLpAddress = '0x11f023F99c5282071a285e9930B42b49b7A230BF' // spooky
    const shrapLpAddress = '0xE69b45BE6260634de4e432F66179ce47EE846800' // to be defined
    const midasLpAddress = '0x02ba4291C54D179CAf7626A562E06328742ec40d' // bombswap

    const bombAddress = getBombAddress()
    const ftmAddress = getWftmAddress()

    const bombFtmPrice = (await getTokenPrice(bombAddress, ftmAddress, bombLpAddress)).div(10 ** 18)

    // console.log(bombFtmPrice.toNumber())

    const bombContract = getContract(erc20, bombAddress)
    const bombSupply = await bombContract.functions.totalSupply()

    const shrapFtmPrice = await getTokenPrice(getShrapnelAddress(), ftmAddress, shrapLpAddress)
    const shrapSupply = await getShrapnelContract().functions.totalSupply()

    const ftmPrice = await getFtmPrice()

    const midasFtmPrice = await getTokenPrice(getMidasAddress(), ftmAddress, midasLpAddress)

    // console.log(ftmPrice.toNumber(), 'fantom price')

    // console.log(new BigNumber(shrapFtmPrice.times(ftmPrice)).toNumber())

    return [
      new BigNumber(ftmPrice).times(bombFtmPrice).toJSON(),
      new BigNumber(bombSupply).toJSON(),
      new BigNumber(ftmPrice).toJSON(),
      new BigNumber(shrapFtmPrice.times(ftmPrice)).toJSON(),
      new BigNumber(shrapSupply).toJSON(),
      new BigNumber(midasFtmPrice.times(ftmPrice)).toJSON(),
    ]
  } catch (e) {
    console.error(e)
    return Array(5).fill(BIG_ZERO.toJSON())
  }
})

export const TokensSlice = createSlice({
  name: 'Tokens',
  initialState: {
    isLoading: true,
    prices: {
      bomb: BIG_ZERO.toJSON(),
      shrap: BIG_ZERO.toJSON(),
      ftm: BIG_ZERO.toJSON(),
      midas: BIG_ZERO.toJSON(),
    },
    supply: {
      bomb: BIG_ZERO.toJSON(),
      shrap: BIG_ZERO.toJSON(),
    },
  },
  reducers: {
    updateBombPrice: (state, action) => {
      const price = action.payload
      state.prices.bomb = price
    },
    updateBombSupply: (state, action) => {
      const supply = action.payload
      state.supply.bomb = supply
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTokenData.fulfilled, (state, action: PayloadAction<string[]>) => {
      const [bombPrice, bombSupply, ftmPrice, shrapPrice, shrapSupply, midasPrice] = action.payload
      state.prices.bomb = bombPrice
      state.supply.bomb = bombSupply
      state.supply.shrap = shrapSupply
      state.prices.shrap = shrapPrice
      state.prices.ftm = ftmPrice
      state.prices.midas = midasPrice

      state.isLoading = false
    })
  },
})

export default TokensSlice.reducer

export const { updateBombPrice } = TokensSlice.actions
