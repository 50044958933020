import { Currency, CurrencyAmount, currencyEquals, ETHER, JSBI, Token, TokenAmount, WETH } from '@fbomb-finance/exchange-sdk'
import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useWeb3Provider'
import { getBombAddress, getWrappedBombAddress } from 'utils/addressHelpers'
import { tryParseAmount } from '../state/swap/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useCurrencyBalance } from '../state/wallet/hooks'
import { useWETHContract, useWrappedBomb } from './useContract'

function BI(n: Parameters<typeof JSBI.BigInt>[0]) {
  try {
    return JSBI.BigInt(n ?? 0)
  } catch (e) {
    return JSBI.BigInt(0)
  }
}

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined,
): {
  wrapType: WrapType;
  execute?: undefined | (() => Promise<void>);
  inputError?: string;
  outputAmount?: CurrencyAmount;
} {
  const { chainId, account } = useActiveWeb3React()
  const wethContract = useWETHContract()
  const wbombContract = useWrappedBomb()

  const WBOMB = useMemo(() => new Token(250, getWrappedBombAddress(), 18), []);
  const BOMB = useMemo(() => new Token(250, getBombAddress(), 0), []);

  const balance = useCurrencyBalance(account ?? undefined, inputCurrency)
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [inputCurrency, typedValue])
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE

    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

    if (inputCurrency === ETHER && currencyEquals(WETH[chainId], outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
              try {
                const txReceipt = await wethContract.deposit({ value: `0x${inputAmount.raw.toString(16)}` })
                addTransaction(txReceipt, { summary: `Wrap ${inputAmount.toSignificant(6)} FTM to WFTM` })
              } catch (error) {
                console.error('Could not deposit', error)
              }
            }
            : undefined,
        inputError: !inputAmount ? "Input an amount" : sufficientBalance ? undefined : 'Insufficient FTM balance',
        outputAmount: inputAmount
      }
    }
    if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === ETHER) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
              try {
                const txReceipt = await wethContract.withdraw(`0x${inputAmount.raw.toString(16)}`)
                addTransaction(txReceipt, { summary: `Unwrap ${inputAmount.toSignificant(6)} WFTM to FTM` })
              } catch (error) {
                console.error('Could not withdraw', error)
              }
            }
            : undefined,
        inputError: !inputAmount ? "Input an amount" : sufficientBalance ? undefined : 'Insufficient WFTM balance',
        outputAmount: inputAmount
      }
    }


    if (currencyEquals(BOMB, inputCurrency) && currencyEquals(WBOMB, outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
              try {
                const txReceipt = await wbombContract.deposit(`0x${inputAmount.raw.toString(16)}`)
                addTransaction(txReceipt, { summary: `Wrap ${inputAmount.toSignificant(6)} BOMB to wBOMB` })
              } catch (error) {
                console.error('Could not deposit', error)
              }
            }
            : undefined,
        inputError: !inputAmount ? "Input an amount" : sufficientBalance ? undefined : 'Insufficient BOMB balance',
        outputAmount:
          new TokenAmount(
            WBOMB,
            BI(
              JSBI.multiply(JSBI.divide(JSBI.multiply(BI(inputAmount?.raw), BI(99)), BI(100)), BI(1e18))
            )
          )
      }
    }
    if (currencyEquals(WBOMB, inputCurrency) && currencyEquals(BOMB, outputCurrency)) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
              try {
                const txReceipt = await wbombContract.withdraw(`0x${inputAmount.raw.toString(16)}`)
                addTransaction(txReceipt, { summary: `Wrap ${inputAmount.toSignificant(6)} BOMB to wBOMB` })
              } catch (error) {
                console.error('Could not deposit', error)
              }
            }
            : undefined,
        inputError: !inputAmount ? "Input an amount" : sufficientBalance ? undefined : 'Insufficient WBOMB balance',
        outputAmount:
          new TokenAmount(
            BOMB,
            BI(
              JSBI.divide(JSBI.multiply(JSBI.divide(BI(inputAmount?.raw), BI(1e18)), BI(99)), BI(100))
            )
          )

      }
    }



    return NOT_APPLICABLE
  }, [wethContract, wbombContract, BOMB, WBOMB, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction])
}
