import BigNumber from 'bignumber.js'
import { DatePickerPortal } from 'components/DatePicker'
import useEagerConnect from 'hooks/useEagerConnect'
import React, { lazy } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { usePollBlockNumber, usePollCoreFarmData, useTokensData } from 'state/hooks'

import { ResetCSS } from '@fbomb-finance/uikit-v2'

import { RedirectOldRemoveLiquidityPathStructure } from 'views/RemoveLiquidity/redirects'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from 'views/AddLiquidity/redirects'
import { RedirectPathToSwapOnly } from 'views/Swap/redirects'

import Wrap from 'views/Wrap'

import EasterEgg from './components/EasterEgg'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
// import Pools from './views/Pools'
import history from './routerHistory'
import GlobalStyle from './style/Global'


// Route-based code splittings
const Manhattan = lazy(() => import('./views/Manhattan'))
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Staking = lazy(() => import('./views/Staking'))
const Partners = lazy(() => import('./views/Partners'))
// const Lottery = lazy(() => import('./views/Lottery'))
const NotFound = lazy(() => import('./views/NotFound'))

// Exchange
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Pool = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Swap = lazy(() => import('./views/Swap'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  useTokensData()
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route exact path='/' render={
              ({ location }) => {
                if (!location.hash) return <Home />
                return <Redirect to={location.hash.replace('#', '')} />
              }
            } />
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/staking">
              <Staking />
            </Route>
            <Route path="/mtm">
              <Manhattan version="Fantom" />
            </Route>
            <Route path="/partners">
              <Partners />
            </Route>

            <Route path="/swap">
              <Swap />
            </Route>
            <Route path="/pool">
              <Pool />
            </Route>
            <Route path="/wrap">
              <Wrap />
            </Route>
            <Route path="/find">
              <PoolFinder />
            </Route>
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />

            <Route component={RedirectPathToSwapOnly} />
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
