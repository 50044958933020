const tokens: {
  [key: string]: {
    symbol: string;
    projectLink?: string;
    decimals?: number;
    address?: {
      [key: number]: string
    }
  }
} = {
  bnb: {
    symbol: 'FTM',
    projectLink: 'https://fantom.foundation/',
  },
  wftm: {
    symbol: 'WFTM',
    decimals: 18,
    address: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      4002: '0x0DD718B496500d158698a49e9Ac900cAc5966Fb0',
    },
    projectLink: 'https://fantom.foundation/',
  },
  wbomb: {
    symbol: 'WBOMB',
    address: {
      250: '0xC09A82aD5075B3067D80F54f05e1E22229699Cc1',
    },
    decimals: 18,
    projectLink: 'https://fbomb.finance/',
  },
  bomb: {
    symbol: 'BOMB',
    address: {
      250: '0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378',
      4002: '0xE3071c04449AF64828Ebf350D0c6B297DEbE0C98',
    },
    decimals: 0,
    projectLink: 'https://fbomb.finance/',
  },
  shrap: {
    symbol: 'SHRAP',
    address: {
      250: '0xbf4906762C38F50bC7Be0A11BB452C944f6C72E1',
      4002: '0xfb3adA4C9317C23ba98076F430300d6AC6E263f8',
    },
    decimals: 18,
    projectLink: 'https://fbomb.finance/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      250: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      4002: '0xEca2563E681df0848D2fE7B739AdEf3791371c13',
    },
    decimals: 6,
    projectLink: 'https://centre.com',
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    },
    decimals: 8,
    projectLink: 'https://www.wbtc.network/',
  },
  weth: {
    symbol: 'WETH',
    address: {
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    },
    decimals: 18,
    projectLink: 'https://weth.io/',
  },
  bbomb: {
    symbol: 'bBOMB',
    address: {
      250: '0x0d318ef2CF0AaFA2713419AEd6c0F6A21FD34Cf0',
    },
    decimals: 18,
  },
  pgunk: {
    symbol: 'PGUNK',
    address: {
      250: '0xf8Fc059dAfDCe4EF2EdFc72cbBAF410d7531E610',
    },
    decimals: 9,
  },
  mim: {
    symbol: 'MIM',
    address: {
      250: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    },
    decimals: 18,
  },

  spirit: {
    symbol: 'SPIRIT',
    address: {
      250: '0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
    },
    decimals: 18,
  },
  boo: {
    symbol: 'BOO',
    address: {
      250: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    },
    decimals: 18,
  },
  grim: {
    symbol: 'GRIM',
    address: {
      250: '0x7eC94C4327dC757601B4273cD67014d7760Be97E',
    },
    decimals: 18,
  },
  spell: {
    symbol: 'SPELL',
    address: {
      250: '0x468003B688943977e6130F4F68F23aad939a1040',
    },
    decimals: 18,
  },
  ice: {
    symbol: 'ICE',
    address: {
      250: '0xf16e81dce15B08F326220742020379B855B87DF9',
    },
    decimals: 18,
  },
  geist: {
    symbol: 'GEIST',
    address: {
      250: '0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
    },
    decimals: 18,
  },
  scream: {
    symbol: 'SCREAM',
    address: {
      250: '0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    },
    decimals: 18,
  },
  zoo: {
    symbol: 'ZOO',
    address: {
      250: '0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    },
    decimals: 0,
  },
  tomb: {
    symbol: 'TOMB',
    address: {
      250: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    },
    decimals: 18,
  },
  beets: {
    symbol: 'BEETS',
    address: {
      250: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
    },
    decimals: 18,
  },
  shade: {
    symbol: 'SHADE',
    address: {
      250: '0x3A3841f5fa9f2c283EA567d5Aeea3Af022dD2262',
    },
    decimals: 18,
  },
  tarot: {
    symbol: 'TAROT',
    address: {
      250: '0xC5e2B037D30a390e62180970B3aa4E91868764cD',
    },
    decimals: 18,
  },
  kek: {
    symbol: 'KEK',
    address: {
      250: '0x627524d78B4fC840C887ffeC90563c7A42b671fD',
    },
    decimals: 18,
  },
  coffin: {
    symbol: 'COFFIN',
    address: {
      250: '0x593Ab53baFfaF1E821845cf7080428366F030a9c',
    },
    decimals: 18,
  },
  reaper: {
    symbol: 'REAPER',
    address: {
      250: '0x117dB78176C8eDe4F12fCd29d85Cd96b91A4cbBb',
    },
    decimals: 18,
  },
  sspell: {
    symbol: 'sSPELL',
    address: {
      250: '0xbB29D2A58d880Af8AA5859e30470134dEAf84F2B',
    },
    decimals: 18,
  },
  nice: {
    symbol: 'nICE',
    address: {
      250: '0x7f620d7d0b3479b1655cEFB1B0Bc67fB0EF4E443',
    },
    decimals: 18,
  },
  wmemeo: {
    symbol: 'wMEMO',
    address: {
      250: '0xDDc0385169797937066bBd8EF409b5B3c0dFEB52',
    },
    decimals: 18,
  },
  echo: {
    symbol: 'ECHO',
    address: {
      250: '0x54477A1D1bb8C1139eEF754Fd2eFd4DDeE7933dd',
    },
    decimals: 9,
  },
  death: {
    symbol: 'Death',
    address: {
      250: '0xeaF45B62d9d0Bdc1D763baF306af5eDD7C0d7e55',
    },
    decimals: 9,
  },
  midas: {
    symbol: 'MIDAS',
    address: {
      250: '0xb37528DA6b4D378305d000a66Ad91bd88E626761',
      4002: '0xf1A842969d1EDCCD7D5DC2f7AD077D077E543776',
    },
    decimals: 18,
  },
  cousd: {
    symbol: 'CoUSD',
    address: {
      250: '0x0DeF844ED26409C5C46dda124ec28fb064D90D27',
    },
    decimals: 18,
  },
  smart: {
    symbol: 'SMART',
    address: {
      250: '0x34D33dc8Ac6f1650D94A7E9A972B47044217600b',
    },
    decimals: 18,
  },
  slurp: {
    symbol: 'SLURP',
    address: {
      250: '0x26AeB3441428eeAf7D653e9A5B455c57Dd8d8EeB',
    },
    decimals: 18,
  },
  qi: {
    symbol: 'QI',
    address: {
      250: '0x68Aa691a8819B07988B18923F712F3f4C8d36346',
    },
    decimals: 18,
  },
  anyfsn: {
    symbol: 'anyFSN',
    address: {
      250: '0x50EB82CC284E3D35936827023B048106aAEcfc5F',
    },
    decimals: 18,
  },
  bdani: {
    symbol: 'bDANI',
    address: {
      250: '0x24B2b8e6fBcFE4a7902E73fb7D439932d23C587F',
    },
    decimals: 18,
  },
  nova: {
    symbol: 'NOVA',
    address: {
      250: '0xA1Ac4AB0E58Bb6CfDFf5797c83cDBE7cB651f7e5',
    },
    decimals: 9,
  },
}

export default tokens
