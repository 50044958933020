import { configureStore, Middleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import farmsReducer from './farms'
import midasFarmsReducer from './midasFarms'
import blockReducer from './block'
import tokensReducer from './tokens'
import mtmReducer from './mtm'

import application from './application/reducer'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import lists from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import toasts from './toasts'
// import { getThemeCache } from '../utils/theme'

/* type MergedState = {
  user: {
    [key: string]: any
  }
  transactions: {
    [key: string]: any
  }
} */

const PERSISTED_KEYS: string[] = ['user', 'transactions']
const loadedState = load({ states: PERSISTED_KEYS })

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    block: blockReducer,
    farms: farmsReducer,
    midasFarms: midasFarmsReducer,
    tokens: tokensReducer,
    mtm: mtmReducer,
    application,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
    toasts,
  },
  middleware: getDefaultMiddleware => [save({ states: PERSISTED_KEYS }) as Middleware].concat(getDefaultMiddleware()),
  preloadedState: loadedState,
})

store.dispatch(updateVersion())

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch()

export default store
