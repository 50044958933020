import React, { useCallback, useMemo } from 'react'
import { CurrencyAmount, currencyEquals, ETHER, Token, TokenAmount, WETH } from '@fbomb-finance/exchange-sdk'
import { ArrowDownIcon, Button, CardBody, IconButton } from '@fbomb-finance/uikit-v2'
import { AutoColumn } from 'components/Column'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Container'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoRow } from 'components/Row'
import { LinkStyledButton } from 'components/Shared'
import { ArrowWrapper, BottomGrouping } from 'components/swap/styleds'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useCurrency } from 'hooks/useTokens'
import useActiveWeb3React from 'hooks/useWeb3Provider'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { noop } from 'lodash'
import { Loader } from 'react-feather'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager } from 'state/user/hooks'
import styled from 'styled-components'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import AppBody from 'views/AppBody'
import wrapPairs from 'config/swap/token/wrapPairs'
import PageHeader from 'components/SwapPageHeader'
import CardNav from 'components/CardNav'

const HalfButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
`

const Wrap: React.FC = () => {
  const { chainId, account } = useActiveWeb3React()
  const { typedValue, recipient } = useSwapState()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { currencyBalances, parsedAmount, currencies } = useDerivedSwapInfo()
  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const [isExpertMode] = useExpertModeManager()

  const [loadedInputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]

  const parsedAmounts = {
    [Field.INPUT]: parsedAmount,
    [Field.OUTPUT]: parsedAmount,
  }

  const wrapETH = useMemo(() =>
    currencyEquals(currencies[Field.OUTPUT], WETH[chainId])
    || currencyEquals(currencies[Field.INPUT], WETH[chainId]),
    [chainId, currencies])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
    outputAmount: wrapOutputAmount
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)

  const [approval, approveCallback] =
    useApproveCallback(
      !wrapETH && wrapType === WrapType.WRAP && parsedAmounts[Field.INPUT]?.raw
        ? new TokenAmount(currencies[Field.INPUT] as Token, parsedAmounts[Field.INPUT].raw)
        : undefined,
      (currencies[Field.OUTPUT] as Token)?.address
    )

  const formattedAmounts = {
    [Field.INPUT]: typedValue,
    [Field.OUTPUT]: wrapOutputAmount?.toExact() ?? ''
  }

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      /* const pair = wrapPairs()[inputCurrency.symbol]
      const outputCurrency = inputCurrency.symbol === pair[0].symbol
        ? !pair[1].address ? ETHER : new Token(chainId, pair[1].address[chainId], pair[1].decimals, pair[1].symbol)
        : !pair[0].address ? ETHER : new Token(chainId, pair[0].address[chainId], pair[0].decimals, pair[0].symbol) */

      const outputToken = wrapPairs()[inputCurrency.symbol]
      const outputCurrency = !outputToken?.address ? ETHER : new Token(chainId, outputToken.address[chainId], outputToken.decimals, outputToken.symbol)

      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencySelection(Field.OUTPUT, outputCurrency)
    },
    [onCurrencySelection, chainId],
  )

  const handleHalveInput = useCallback(
    () => {
      if (currencyBalances.INPUT) {
        handleTypeInput(currencyBalances.INPUT.divide(BigInt(2)).toFixed(loadedInputCurrency?.decimals ?? 18))
      }
    }, [handleTypeInput, currencyBalances, loadedInputCurrency]
  )

  return (
    <Container>
      <CardNav activeIndex={2} />
      <AppBody>
        <PageHeader title="Wrap" />
        <CardBody>
          <AutoColumn gap="md">
            <CurrencyInputPanel
              label='From'
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="wrap-currency-input"
              onlyWrapping
            />
            <AutoColumn justify="space-between">
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem', position: 'relative' }}>
                <ArrowWrapper clickable>
                  <IconButton
                    variant="tertiary"
                    onClick={() => {
                      onSwitchTokens()
                      if (!wrapETH) {
                        handleTypeInput(formattedAmounts[Field.INPUT])
                      }
                    }}
                    style={{ borderRadius: '50%' }}
                    scale="sm"
                  >
                    <ArrowDownIcon color="border" width="24px" />
                  </IconButton>
                </ArrowWrapper>
                <HalfButtonWrapper>
                  <LinkStyledButton
                    onClick={() => { handleHalveInput() }}
                    style={{ fontSize: '12pt' }}>
                    50%
                  </LinkStyledButton>
                </HalfButtonWrapper>
                {recipient === null && isExpertMode ? (
                  <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                    + Add a send (optional)
                  </LinkStyledButton>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={wrapETH ? handleTypeOutput : noop}
              label='To'
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={noop}
              otherCurrency={currencies[Field.INPUT]}
              disableCurrencySelect
              id="swap-currency-output"
            />
          </AutoColumn>

          <BottomGrouping>
            {!account ? (
              <ConnectWalletButton width="100%" />
            ) : wrapETH ? (
              <Button disabled={Boolean(wrapInputError)} onClick={onWrap} width="100%">
                {wrapInputError ??
                  (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
              </Button>
            ) : !wrapETH ? (
              <Button
                disabled={Boolean(wrapInputError) || wrapType === WrapType.NOT_APPLICABLE}
                onClick={approval === ApprovalState.APPROVED || wrapType === WrapType.UNWRAP ? onWrap : approveCallback}
                width="100%">
                {approval === ApprovalState.APPROVED || wrapType === WrapType.UNWRAP ? (
                  wrapInputError ??
                  (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)
                ) : (
                  approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader stroke="white" />
                    </AutoRow>
                  ) : !currencies[Field.INPUT]?.symbol ? (
                    "Select a token"
                  ) : approval === ApprovalState.UNKNOWN && parsedAmounts?.[Field.INPUT]?.greaterThan('0') ? (
                    <AutoRow gap="6px" justify="center">
                      Loading <Loader stroke="white" />
                    </AutoRow>
                  ) : (
                    `Approve ${currencies[Field.INPUT]?.symbol ?? ''}`
                  )
                )}
              </Button>
            ) : null}
          </BottomGrouping>
        </CardBody>
      </AppBody>
    </Container>
  )
}

export default Wrap